// required modules
import Service from '@/services/api/Service'
import McLibraryModel from '@/models/MiningCompany/McLibrary'

/**
 * This class describes a McLibrary service.
 *
 * @class McLibrary (name)
 */
export default class McLibraryService extends Service {

  /**
   * 
   */
  static create(mcLibrary) {
    return mcLibrary.transfer(mcLibrary.form([ 
      'name', 
      'folderPath',
      'isPublic', 
      'file',
      'content' 
    ]))
  }

  /**
   * 
   */
  static delete(mcLibrary) {
    return this.shape(mcLibrary).delete()
  }

  /**
   * 
   */
  static download(mcLibrary) {
    return this.shape(mcLibrary).many('content').query().fetch({ responseType: 'blob' }).then(res => {

      // link element
      var fileLink = document.createElement('a')
      var fileName = mcLibrary.filename
      var fileURL = URL.createObjectURL(res.data)

      // return blob file
      fileLink.href = fileURL
      fileLink.download = fileName
      fileLink.click()
    })
  }

  /**
   * Define the McLibrary model.
   *
   * @return {Function} McLibrary resource.
   */
  static model() {
    return McLibraryModel
  }

  /**
   * 
   */
  static getByFolder(folderPath = '') {
    return this
      .query()
      .param('folderPath', folderPath)
      .get()
  }

  /**
   * 
   * @param {*} mcLibrary 
   */
  static toCreate() {
    return this.shape({
      name: 'Mi Archivo',
      folderPath: '',
      isPublic: '1',
      file: null,
      subfolder: '', 
      content:'',
      // file: 'Archivo en formato form-data',
    })
  }

  /**
   * 
   * @param {*} mcLibrary 
   */
  static toFolder() {
    return { 
      // name: 'Nombre de la carpeta', 
      name: '', 
      // path: "carpeta/", 
      path: '/', 
      // fullPath: "root/carpeta/",
      fullPath: 'root/carpeta/',
    }
  }

  /**
   * 
   */
  static update(mcLibrary) {
    return this.shape(mcLibrary).transfer(this.shape(mcLibrary).form([
      'name', 
      'folderPath',
      'isPublic', 
      'file',
      'content',
    ]))
  }
}